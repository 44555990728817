import fetch from ".";

const bookingService = {};

const Route = "/booking";

bookingService.createBooking = async function (sendingData) {
  try {
    const response = await fetch({
      url: `${Route}`,
      method: "post",
      data: sendingData,
    });

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

bookingService.verifyPayment = async function (sendingData) {
  try {
    const response = await fetch({
      url: `${Route}/verify-payment`,
      method: "post",
      data: sendingData,
    });

    return response;
  } catch (err) {
    console.log(err);
  }
};

bookingService.getBookingPdf = async function (bookingId) {
  try {
    const response = await fetch({
      url: `${Route}/${bookingId}/pdf`,
      method: "get",
    });

    return response;
  } catch (err) {
    console.log(err);
  }
};

bookingService.getBookingById = async function (bookingId, headers) {
  try {
    const response = await fetch({
      url: `${Route}/${bookingId}`,
      method: "get",
      headers: headers,
    });

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

bookingService.sendBookingPdf = async function (bookingId, headers) {
  try {
    const response = await fetch({
      url: `${Route}/${bookingId}/sentEmailPdf`,
      method: "get",
      // headers: headers,
    });

    return response;
  } catch (err) {
    console.log(err);
  }
};

export default bookingService;
