"use client";
import React, { useEffect, useState } from "react";
import tripService from "../services/tripService";
import bustopService from "../services/bustopService";
import { useNavigate } from "react-router-dom";
import ayyapanImg from "../assets/ayyapan.png";

const TripSearchInput = ({ trips }) => {
  const [tripId, setTripId] = useState({});
  const [pickupLocations, setPickupLocations] = useState([]);
  const [pickupLocationId, setPickupLocationId] = useState(null);
  const [secondaryTripId, setSecondaryTripId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (trips.length > 0) {
      setTripId(trips[0]._id);
      getPickupLocation();
    }
  }, [trips]);

  // useEffect(() => {
  //   if(trips.length > 0) {

  //   }
  // },[tripId])

  const getPickupLocation = async () => {
    const tripPickUpLocation = await bustopService.getBustops();

    if (tripPickUpLocation) {
      setPickupLocations(tripPickUpLocation[0].busStops);
      setPickupLocationId(tripPickUpLocation[0].busStops[0]._id);
    }
  };

  let price =
    pickupLocations?.length > 0 && pickupLocationId
      ? pickupLocations.find((cur) => cur._id === pickupLocationId).price
      : "";

  //   const getPickUpLocation = (tripId) => {
  //     const price = pickupLocations.find((cur) => cur._id === tripId);
  //     if (price.price) {
  //       return price.price;
  //     }
  //     return "";
  //   };

  const onBookingClick = async () => {
    if (secondaryTripId) {
      navigate(
        `/booking/${tripId}?pickupstopId=${pickupLocationId}&secondaryTripId=${secondaryTripId}`
      );
    } else {
      navigate(`/booking/${tripId}?pickupstopId=${pickupLocationId}`);
    }
  };

  const fetchIfSecondaryTrip = (busStopId) => {
    // const pickupLocation = pickupLocations.find((cur) => cur._id === busStopId);
    // let pickupLocationName = pickupLocation.busStopName;
    // const stops = ["Guruvayoor", "Vadanapally", "Thripayar"];
    // if (stops.includes(pickupLocationName)) {
    //   // setSecondaryTripId();
    //   const findCurrentTripIndex = trips.findIndex((cur) => cur._id === tripId);
    //   setSecondaryTripId(trips[findCurrentTripIndex - 1]._id);
    // } else {
    //   setSecondaryTripId(null);
    // }
  };

  useEffect(() => {
    if (pickupLocations?.length > 0 && trips?.length > 0) {
      const pickupLocation = pickupLocations.find(
        (cur) => cur._id === pickupLocationId
      );
      let pickupLocationName = pickupLocation.busStopName;

      const stops = ["Guruvayoor", "Vadanapally", "Thripayar"];

      if (stops.includes(pickupLocationName)) {
        // setSecondaryTripId();

        const findCurrentTripIndex = trips.findIndex(
          (cur) => cur._id === tripId
        );

        setSecondaryTripId(trips[findCurrentTripIndex - 1]._id);
      } else {
        setSecondaryTripId(null);
      }
    }
  }, [pickupLocationId, tripId, pickupLocations, trips]);

  // useEffect(() => {
  //   if (tripId && trips?.length > 0 && pickupLocations?.length > 0) {
  //     const tripDetails = trips.find((cur) => cur._id === tripId);

  //     console.log(tripDetails, "hereeee");

  //     if (tripDetails?.trip_name === "17-11-2024 trip sabrimala") {
  //       // alert("hittt");
  //       setPickupLocationId("671bd49cc200f50900fe0912");
  //     } else {
  //       setPickupLocationId(pickupLocations[0]._id);
  //     }
  //   }
  // }, [tripId, pickupLocations, trips]);

  return (
    // py-16 px-8
    <>
      {pickupLocations?.length > 0 && trips?.length > 0 ? (
        <div className="max-w-screen-xl mx-auto -translate-y-16">
          <div className="flex items-center -mt-6 ml-2">
            <h2 className="text-2xl font-bold text-white mb-3 text-nowrap">
              Book Your <span className="text-[#a2e53f]">Sabarimala Seats</span>{" "}
            </h2>
            <img src={ayyapanImg} alt="Ayyapan" style={{ height: 100 }} />
          </div>

          <div className="bg-white  relative z-50 shadow-md rounded-lg grid grid-cols-1 md:grid-cols-4 gap-4 overflow-hidden">
            <div className="flex items-center justify-between w-full border-b md:border-b-0 border-gray-300 pb-2 mb-2 md:mb-0">
              <select
                name="tripname"
                value={tripId}
                className="h-24 border-none focus:ring-0 px-8 w-full"
                onChange={(e) => {
                  const tripDetails = trips.find(
                    (cur) => cur._id === e.target.value
                  );

                  if (
                    // tripDetails?.trip_name === "24-12-2024 Sabarimala" ||
                    // tripDetails?.trip_name === "31-12-2024 Sabarimala" ||
                    tripDetails?.trip_name === "16-01-2025 Sabarimala"
                  ) {
                    // alert("hittt");
                    setPickupLocationId("671c8c99711094a9ee9b2368");
                  } else {
                    setPickupLocationId(pickupLocations[0]._id);
                  }

                  setTripId(e.target.value);

                  // getPickupLocation(e.target.value);
                }}
              >
                {trips.map((cur) => (
                  <option value={cur._id}>{cur.trip_name}</option>
                ))}
              </select>
              <div className="h-[60%] bg-gray-200 w-[1px] ml-4 hidden md:block"></div>
            </div>
            <div className="flex items-center justify-between w-full border-b md:border-b-0 border-gray-300 pb-2 mb-2 md:mb-0">
              <select
                name="pickup"
                className="h-24 border-none focus:ring-0 px-8 w-full"
                value={pickupLocationId}
                onChange={(e) => {
                  setPickupLocationId(e.target.value);
                  // fetchIfSecondaryTrip(e.target.value);
                }}
              >
                {pickupLocations.map((cur) => (
                  <option
                    value={cur._id}
                    key={cur.busStopName}
                    disabled={
                      trips.findIndex((cur) => cur._id === tripId) === 0
                        ? ["Guruvayoor", "Vadanapally", "Thripayar"].includes(
                            cur.busStopName
                          )
                        : // : /*24-12-2024 */ tripId === "670bb1c5fd35e0250e389c99"
                        // ? !["Guruvayoor", "Vadanapally", "Thripayar"].includes(
                        //     cur.busStopName
                        //   )
                        tripId === "670dedb97fa6ffc8216612f1" /*31-12-2024 */
                        ? ["Guruvayoor", "Vadanapally", "Thripayar"].includes(
                            cur.busStopName
                          )
                        : /*16-01-2025 */ tripId ===
                            "670defa77fa6ffc821661323" &&
                          !["Guruvayoor", "Vadanapally", "Thripayar"].includes(
                            cur.busStopName
                          )

                      // (trips.findIndex((cur) => cur._id === tripId) === 0 ||
                      // trips.find(
                      //   (cur) => cur.trip_name === "31-12-2024 Sabarimala"
                      // )
                      //   ? ["Guruvayoor", "Vadanapally", "Thripayar"].includes(
                      //       cur.busStopName
                      //     )

                      // : trips.find(
                      //     (cur) => cur.trip_name === "24-12-2024 Sabarimala"
                      //   )
                      // ? !["Guruvayoor", "Vadanapally", "Thripayar"].includes(
                      //     cur.busStopName
                      //   )
                      // : trips.find(
                      //     (cur) => cur.trip_name === "31-12-2024 Sabarimala"
                      //   )
                      // ? ["Guruvayoor", "Vadanapally", "Thripayar"].includes(
                      //     cur.busStopName
                      //   )
                      // : trips.find(
                      //     (cur) => cur.trip_name === "16-01-2025 Sabarimala"
                      //   ) &&
                      //   !["Guruvayoor", "Vadanapally", "Thripayar"].includes(
                      //     cur.busStopName
                      //   )
                    }
                  >
                    {cur.busStopName}
                  </option>
                ))}
              </select>
              <div className="h-[60%] bg-gray-200 w-[1px] ml-4 hidden md:block"></div>
            </div>

            <div className="flex items-center justify-between w-full border-b md:border-b-0 border-gray-300 pb-2 mb-2 md:mb-0">
              {/* <select
                name="location"
                id="location"
                className="h-24 border-none focus:ring-0 px-8 w-full"
              >
                <option value="dubai">Dubai, UAE</option>
              </select> */}
              <input
                type="text"
                placeholder="Try Mustang..."
                className="h-24 border-none focus:ring-0 px-8 w-full"
                // value={getPricing(pickupLocationId)}
                value={`₹${price} per Person`}
                disabled
              />
              <div className="h-[60%] bg-gray-200 w-[1px] ml-4 hidden md:block"></div>
            </div>

            <div className="w-full">
              <button
                type="button"
                className="text-dark bg-[#a2e53f] focus:outline-none w-full h-24 text-lg font-semibold"
                onClick={onBookingClick}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      ) : (
        <h2>Ooops! Cannot Book Now.. Try Again</h2>
      )}
    </>
  );
};

export default TripSearchInput;
