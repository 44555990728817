import React from "react";
import Layout from "../components/Layout/Layout";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
        <div className="max-w-6xl w-full bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center">
            About Us
          </h1>
          <p className="text-gray-600 text-lg mb-6">
            In the travel and tourism sector, Vismaya Greenlines Private Limited
            has established itself by offering transport services within Kerala
            and expanding to neighboring states like Karnataka and Tamil Nadu.
            The company focuses on enhancing efficiency in passenger
            transportation, with plans to diversify into interstate bus
            services. By leveraging its expertise in logistics and technology,
            Vismaya aims to improve travel experiences, offering safer and more
            cost-effective services through monitoring systems and AI-based
            innovations. Our expansion into tourism aligns with India's growing
            market, and Our strategy includes integrating advanced technologies
            to streamline travel operations, such as reducing delays and
            improving service quality
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
            <div>
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                Our Mission
              </h2>
              <p className="text-gray-600">
                To revolutionize travel experiences by providing safe,
                efficient, and cost-effective transportation services. We aim to
                continuously innovate through technology, ensuring seamless and
                enjoyable journeys for our passengers while expanding our reach
                across states.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                Why Choose Us?
              </h2>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Real-time seat availability</li>
                <li>Secure online payments</li>
                <li>Wide range of routes and operators</li>
                <li>User-friendly interface for easy booking</li>
              </ul>
            </div>
          </div>

          <div className="text-center mt-12">
            <button
              className="bg-[#a2e53f]  text-white font-bold py-2 px-4 rounded"
              onClick={() => navigate("/")}
            >
              Book Your Seat Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
