import React, { useEffect, useState } from "react";
import SeatSelector from "../components/SeatSelector";
import Layout from "../components/Layout/Layout";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBustops,
  fetchTripById,
} from "../reduxState/features/bookingSlice";
import TripCard from "../components/TripCard";
import { toast } from "react-toastify";
import bookingService from "../services/bookingService";
import BookingSuccess from "../components/SuccessPayment";
import LoadingSpinner from "../components/LoadingSpinner";
// import "react-toastify/dist/ReactToastify.css";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const BusBookingPage = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { selectedTrip, busStops } = useSelector((state) => state.booking);
  const [searchParams] = useSearchParams();
  let pickupStopId = searchParams.get("pickupstopId");
  let secondaryTripId = searchParams.get("secondaryTripId");

  const [selectedSeats, setSelectedSeats] = useState([]);
  const [selectedBusStop, setSelectedBusStop] = useState(null);
  const [price, setPrice] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookedDetails, setBookedDetails] = useState({});
  const [currentPage, setCurrentPage] = useState("details"); // details or success

  const [bookedCustomer, setBookedCustomer] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
  });

  const [travellers, setTravellers] = useState([]); //[{ fullName: "", age: "" }]

  useEffect(() => {
    if (pickupStopId && busStops?.length > 0) {
      handleBusStopChange(pickupStopId);
    }
  }, [pickupStopId, busStops]);

  const handleTravellerChange = (index, field, value) => {
    // let updatedTravellers = [...travellers];
    // updatedTravellers[index][field] = value;

    // console.log(updatedTravellers, "show-herer");
    // setTravellers(updatedTravellers);

    // Create a new copy of the travellers array
    const updatedTravellers = travellers.map((traveller, i) => {
      // For the traveller being updated, return a new object with the updated field
      if (i === index) {
        return {
          ...traveller, // Copy the existing properties
          [field]: value, // Update the specific field
        };
      }
      // For other travellers, return the original object
      return traveller;
    });

    setTravellers(updatedTravellers); // Update the state with the new array
  };

  const handleBookNow = () => {
    setIsModalVisible(true);
  };

  const validateInputs = () => {
    const { fullName, email, phoneNumber } = bookedCustomer;

    // Validate Booked Customer Details
    if (!fullName.trim()) {
      toast.error("Customer full name is required!");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      toast.error("Valid email is required!");
      return false;
    }

    const phoneRegex = /^\d{10}$/; // Example: 10-digit phone number
    if (!phoneNumber.trim() || !phoneRegex.test(phoneNumber)) {
      toast.error("Valid phone number is required!");
      return false;
    }

    // Validate Other Travellers' Details
    for (let i = 0; i < travellers.length; i++) {
      const traveller = travellers[i];
      if (!traveller.fullName.trim()) {
        toast.error(`Full name is required for traveller ${i + 1}!`);
        return false;
      }

      if (!traveller.age.trim() || isNaN(traveller.age) || traveller.age <= 0) {
        toast.error(`Valid age is required for traveller ${i + 1}!`);
        return false;
      }
    }

    return true;
  };

  const handleConfirmBooking = async () => {
    if (validateInputs()) {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      let orderBookingFields = {
        bus: selectedTrip.bus._id,
        bookedCustomer: bookedCustomer,
        travellers: travellers,
        seatNumbers: selectedSeats,
        totalAmount:
          selectedSeats.length * price +
          (5 / 100) * selectedSeats.length * price +
          (selectedTrip?.bus.name === "Multi axle" ? 201 : 0),
        bookedBusStop: selectedBusStop.busStopName,
        trip: selectedTrip._id,
      };

      const createBooking = await bookingService.createBooking(
        orderBookingFields
      );

      if (!createBooking) {
        toast.error("Server error. Are you online?");
        return;
      }

      // Getting the order details back
      const { amount, id: order_id, currency, bookingId } = createBooking;

      console.log(amount, order_id, currency, "hererere");

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "Vismaya Greenlines Private Limited",
        description: "Bus Booking",
        //   image: { logo },
        order_id: order_id,
        handler: async function (response) {
          console.log(response, "RESPONSE");

          const data = {
            order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };

          try {
            const verifyPayment = await bookingService.verifyPayment(data);

            if (verifyPayment.success) {
              toast.success("Payment was Done... Thank you for Booking!");
              setBookedDetails({
                bookingId,
                ...data,
                bookedInfo: verifyPayment.data,
              });

              setCurrentPage("success");
              setIsModalVisible(false);

              // TODO: Re-enable this
              // const bookedPdf = await bookingService.sendBookingPdf(bookingId);

              // if (bookedPdf) {
              //   toast.success(bookedPdf?.message);
              // }
            } else {
              toast.error("Payment Failed");
            }
          } catch (err) {
            toast.error("Payment Failed!");
          }
        },
        prefill: {
          name: bookedCustomer?.fullName | "Customer",
          email: bookedCustomer?.email,
          contact: bookedCustomer?.phoneNumber,
        },
        // modal: {
        //   ondismiss: async function () {
        //     // This function is called when the payment modal is dismissed or closed
        //     toast.warning("Payment process was canceled or not completed.");

        //     // Update the backend to mark this payment as canceled
        //     // await bookingService.updatePaymentStatus(order_id, "cancelled");
        //   },
        // },
        notes: {
          name: bookedCustomer?.fullName | "Customer",
          email: bookedCustomer?.email,
          contact: bookedCustomer?.phoneNumber,
        },
        //   theme: {
        //     color: "#61dafb",
        //   },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };

  useEffect(() => {
    if (secondaryTripId) {
      dispatch(fetchTripById(secondaryTripId));
      dispatch(fetchBustops());
    } else if (id && !secondaryTripId) {
      dispatch(fetchTripById(id));
      dispatch(fetchBustops());
    }
  }, [id, secondaryTripId, dispatch]);

  // const handleSeatSelection = (seatNumber) => {
  //   if (selectedSeats.includes(seatNumber)) {
  //     setSelectedSeats((prevSelectedSeats) =>
  //       prevSelectedSeats.filter((seat) => seat !== seatNumber)
  //     );
  //   } else if (selectedSeats.length < 4) {
  //     const totalSeats = [...selectedSeats, seatNumber];
  //     setTravellers(Array(totalSeats.length).fill({ fullName: "", age: "" }));
  //     setSelectedSeats((prevSelectedSeats) => [
  //       ...prevSelectedSeats,
  //       seatNumber,
  //     ]);
  //   } else {
  //     alert("You can select up to 4 seats only");
  //   }
  // };

  const handleSeatSelection = (seatNumber) => {
    if (selectedSeats.includes(seatNumber)) {
      // Deselect the seat
      setSelectedSeats((prevSelectedSeats) => {
        const updatedSeats = prevSelectedSeats.filter(
          (seat) => seat !== seatNumber
        );
        // Update the travellers array to match the number of remaining seats
        setTravellers((prevTravellers) =>
          prevTravellers.slice(0, updatedSeats.length)
        );
        return updatedSeats;
      });
    } else if (selectedSeats.length < 10) {
      // Select the seat
      setSelectedSeats((prevSelectedSeats) => {
        const updatedSeats = [...prevSelectedSeats, seatNumber];
        // Update travellers array based on the new length of selected seats
        setTravellers((prevTravellers) => [
          ...prevTravellers,
          { fullName: "", age: "" },
        ]);
        return updatedSeats;
      });
    } else {
      alert("You can select up to 10 seats only");
    }
  };

  const handleBusStopChange = (id) => {
    const selectedStop = busStops?.find((stop) => stop._id === id);
    if (selectedStop) {
      setSelectedBusStop(selectedStop);
      setPrice(selectedStop.price);
    }
  };

  if (!selectedTrip?._id) return <LoadingSpinner />;

  return (
    <Layout>
      {currentPage === "details" ? (
        <>
          <div className="container max-w-3xl mx-auto px-4 py-8">
            {/* <ToastContainer /> */}
            <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">
              Bus Booking
            </h1>

            {/* Bus Details */}
            <div className="max-w-4xl mx-auto">
              <TripCard
                trip={selectedTrip}
                isChangeDepToArrival={secondaryTripId}
              />
            </div>

            {/* Bus Stop Selection */}
            {/* <div className="my-6">
              <label className="block text-gray-700 text-lg mb-2">
                Select Your Pickup Bus Stop:
              </label>
              <select
                className="block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-500"
                onChange={handleBusStopChange}
                defaultValue=""
              >
                <option value="" disabled>
                  Select a bus stop
                </option>
                {busStops?.map((stop) => (
                  <option key={stop._id} value={stop._id}>
                    {stop.busStopName}
                  </option>
                ))}
              </select>
            </div> */}

            {/* Display Selected Bus Stop Details */}
            {selectedBusStop && (
              <div className="bg-white shadow-lg rounded-lg p-6 mt-4">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                  Selected Bus Stop Details
                </h3>
                <p className="text-gray-600">
                  <strong>Bus Stop: </strong>
                  {selectedBusStop.busStopName}
                </p>
                {/* <p className="text-gray-600">
                  <strong>Departure Date: </strong>
                  {selectedBusStop.departureTime.includes("AM")
                    ? selectedTrip.expected_reach.date
                    : selectedTrip.expected_go.date}
                </p> */}
                {/* <p className="text-gray-600">
                  <strong>Estimated Departure Time: </strong>
                  {selectedBusStop.departureTime}
                </p> */}
                <p className="text-gray-600">
                  <strong>Price: </strong>₹
                  {selectedTrip?.bus?.name === "Multi axle"
                    ? Number(selectedBusStop.price) + 201
                    : selectedBusStop.price}
                </p>
              </div>
            )}

            {/* Seat Selector */}
            {selectedTrip?.bus?.seats?.length > 0 && (
              <SeatSelector
                selectedSeats={selectedSeats}
                handleSeatSelection={handleSeatSelection}
                seats={selectedTrip?.bus?.seats}
                bookedSeats={selectedTrip?.bookedSeats}
              />
            )}

            {/* Booking Summary */}
            <div className="bg-white shadow-lg rounded-lg p-6 mt-8">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Booking Summary
              </h3>

              <p className="text-gray-600">
                <strong>Selected Seats: </strong>
                {selectedSeats.length > 0
                  ? selectedSeats.map((seat) => (
                      <span
                        key={seat}
                        className="inline-block px-2 py-1 bg-blue-500 text-white rounded-full text-xs mr-2"
                      >
                        {seat}
                      </span>
                    ))
                  : "None"}
              </p>
              {selectedSeats.length > 0 && price ? (
                <>
                  <p className="text-gray-600">
                    <strong>Total Amount: </strong>₹{" "}
                    {selectedSeats.length * selectedBusStop.price +
                      (5 / 100) * selectedSeats.length * selectedBusStop.price +
                      (selectedTrip?.bus.name === "Multi axle" ? 201 : 0)}
                  </p>

                  <p className="text-gray-600">
                    <strong>GST: </strong> Includes 5%
                  </p>
                </>
              ) : (
                <p className="text-gray-600">
                  Select seats and a bus stop to see the price.
                </p>
              )}

              <button
                className={`mt-4 px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg ${
                  selectedSeats.length === 0 || !selectedBusStop
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={selectedSeats.length === 0 || !selectedBusStop}
                onClick={handleBookNow}
              >
                Book Now
              </button>
            </div>
          </div>

          {/* Booking Modal */}
          {isModalVisible && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl relative flex flex-col h-full">
                {/* Modal Header */}
                <div className="sticky top-0 bg-white z-10 p-6 border-b flex justify-between items-center">
                  <h3 className="text-lg font-bold">Booking Summary</h3>
                  <button
                    onClick={() => setIsModalVisible(false)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    X
                  </button>
                </div>

                {/* Booking Details - Scrollable Content */}
                <div className="flex-1 overflow-y-auto p-6">
                  <div>
                    <h4 className="text-lg font-semibold">Booking Details</h4>
                    <input
                      type="text"
                      placeholder="Full Name"
                      value={bookedCustomer.fullName}
                      onChange={(e) =>
                        setBookedCustomer({
                          ...bookedCustomer,
                          fullName: e.target.value,
                        })
                      }
                      className="border p-2 w-full mt-2 rounded"
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      value={bookedCustomer.email}
                      onChange={(e) =>
                        setBookedCustomer({
                          ...bookedCustomer,
                          email: e.target.value,
                        })
                      }
                      className="border p-2 w-full mt-2 rounded"
                    />
                    <input
                      type="text"
                      placeholder="Phone Number"
                      value={bookedCustomer.phoneNumber}
                      onChange={(e) =>
                        setBookedCustomer({
                          ...bookedCustomer,
                          phoneNumber: e.target.value,
                        })
                      }
                      className="border p-2 w-full mt-2 rounded"
                    />
                  </div>

                  {selectedSeats.length > 0 && (
                    <div className="mt-6">
                      <h4 className="text-lg font-semibold">
                        Travellers Details
                      </h4>
                      {travellers.map((traveller, index) => (
                        <div
                          key={index}
                          className="mt-2 p-4 border border-gray-300 rounded-lg shadow-md bg-white"
                        >
                          <input
                            type="text"
                            placeholder="Full Name"
                            value={traveller.fullName}
                            onChange={(e) =>
                              handleTravellerChange(
                                index,
                                "fullName",
                                e.target.value
                              )
                            }
                            className="border p-2 w-full mt-1 rounded"
                          />
                          <input
                            type="number"
                            placeholder="Age"
                            value={traveller.age}
                            onChange={(e) =>
                              handleTravellerChange(
                                index,
                                "age",
                                e.target.value
                              )
                            }
                            className="border p-2 w-full mt-2 rounded"
                          />
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Card for Selected Bus Stop Details and Pricing Summary */}
                  <div className="mt-4 p-4 border border-gray-300 rounded-lg shadow-md bg-white">
                    <h4 className="font-semibold">Booking Summary</h4>
                    <div className="mt-2">
                      <p className="text-gray-600">
                        <strong>Bus Stop: </strong>
                        {selectedBusStop.busStopName}
                      </p>
                      <p className="text-gray-600">
                        {/* <strong>Departure Date: </strong> */}
                        {/* {selectedTrip.expected_go.date} */}

                        {/* {selectedBusStop.departureTime.includes("AM")
                          ? selectedTrip.expected_reach.date
                          : selectedTrip.expected_go.date} */}
                      </p>
                      {/* <p className="text-gray-600">
                        <strong>Estimated Departure Time: </strong>
                        {selectedBusStop.departureTime}
                      </p> */}
                    </div>
                    <div className="mt-2">
                      <h4 className="font-semibold">
                        Total Seats Booked: {selectedSeats.length}
                      </h4>

                      {selectedSeats.length > 0 ? (
                        selectedSeats.map((seat) => (
                          <span
                            key={seat}
                            className="inline-block px-2 py-1 bg-blue-500 text-white rounded-full text-xs mr-3"
                          >
                            {seat}
                          </span>
                        ))
                      ) : (
                        <p>No seats selected.</p>
                      )}

                      <p className="text-gray-600 mt-2">
                        <strong>GST: </strong> Includes 5%
                      </p>

                      <h4 className="font-semibold mt-2">
                        Total Amount: ₹
                        {selectedSeats.length * selectedBusStop.price +
                          (5 / 100) *
                            selectedSeats.length *
                            selectedBusStop.price +
                          (selectedTrip?.bus.name === "Multi axle" ? 201 : 0)}
                      </h4>
                    </div>
                  </div>
                </div>

                {/* Confirm Booking Button - Fixed Footer */}
                <div className="sticky bottom-0 bg-white z-10 p-6 border-t">
                  <button
                    onClick={handleConfirmBooking}
                    className="bg-green-500 text-white px-4 py-2 rounded-lg w-full hover:bg-green-600 transition duration-300"
                  >
                    Confirm Booking
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {bookedDetails?.bookingId ? (
            <BookingSuccess bookedDetails={bookedDetails} />
          ) : (
            "Somethin went wrong! But You're Booking is Successful "
          )}
        </>
      )}
    </Layout>
  );
};

export default BusBookingPage;
