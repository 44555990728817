// import axios from "axios";

// const API_BASE_URL = "http://localhost:5000/api";

// const service = axios.create({
//   baseURL: API_BASE_URL,
//   timeout: 60000,
// });

// // API Request interceptor
// service.interceptors.request.use(
//   (config) => {
//     return config;
//   },
//   (error) => {
//     // Do something with request error here
//     Promise.reject(error);
//   }
// );

// // API respone interceptor
// service.interceptors.response.use(
//   (response) => {
//     return response.data;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export default service;

import axios from "axios";
import { toast } from "react-toastify";

// // Initialize react-toastify
// toast.configure();

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// API Response interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Extract the message from the error response
    let errorMessage = "An unexpected error occurred";
    console.log(error.response, "erroror");
    if (error.response) {
      // If the server responded with a status code other than 2xx
      if (error.response.data?.errors) {
        error.response.data?.errors?.forEach((err) => {
          toast.error(`${err.path}: ${err.msg}`);
        });
      } else {
        toast.error(error.response.data?.message || error.response.statusText);
      }
    } else if (error.request) {
      // If the request was made but no response was received
      toast.error("No response from the server. Please try again.");
    } else {
      // If something happened in setting up the request
      toast.error(error.message);
    }

    // Show toast notification with the error message
    toast.error(errorMessage);

    // Reject the promise with the error object
    return Promise.reject(error);
  }
);

export default service;
