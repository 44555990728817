import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Booking from "./pages/Booking";
import BusBookingPage from "./pages/BusBookingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndCondition";
import CancellationRefundPolicy from "./pages/CancellationRefundPolicy";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/booking/:id" element={<BusBookingPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms&conditions" element={<TermsAndConditions />} />
        <Route
          path="/cancellation-refundpolicy"
          element={<CancellationRefundPolicy />}
        />
      </Routes>
    </div>
  );
}

export default App;
