import React from "react";
import Layout from "../components/Layout/Layout";

const Contact = () => {
  return (
    <Layout>
      <div className="min-h-screen flex flex-col items-center justify-center bg-white text-gray-800 p-4">
        <div className="w-full max-w-md">
          <h1 className="text-3xl font-bold mb-6 text-center">Contact Us</h1>
          <p className="text-lg mb-8 text-center">
            We’re here to help. Reach out to us with any inquiries or support
            requests.
          </p>
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <div className="mb-4">
              <h2 className="text-xl font-semibold">Customer Care</h2>
              <p className="text-md mt-2">
                <a
                  href="mailto:vismayaexpresslines@gmail.com"
                  className="underline text-blue-600 hover:text-blue-800"
                >
                  vismayaexpresslines@gmail.com
                </a>
              </p>
            </div>
            <div className="mb-4">
              <h2 className="text-xl font-semibold">WhatsApp</h2>
              <p className="text-md mt-2">
                <a
                  href="https://wa.me/9846090098"
                  className="underline text-blue-600 hover:text-blue-800"
                >
                  9846090098
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
