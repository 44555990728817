import React, { useState } from "react";
import Button from "../Button";
import { FiMenu } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
// import whiteLogo from "../../assets/white_logo.png";
import greenLogo from "../../assets/white_logo.png";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle menu visibility
  };

  return (
    <div>
      <nav className="bg-black w-full shadow-lg">
        <div className="w-full h-[100px] px-6 md:px-12 py-2 flex justify-between items-center">
          <h1
            className="text-xl font-bold cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img src={greenLogo} alt="logo" style={{ width: 180 }} />
          </h1>
          <div className="flex gap-10 items-center">
            <ul className="hidden md:flex space-x-6 gap-8">
              <li>
                <Link
                  to="/"
                  className="text-white hover:text-[#a2e53f] text-xl"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="text-white hover:text-[#a2e53f] text-xl"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-white hover:text-[#a2e53f] text-xl"
                >
                  Contact
                </Link>
              </li>
            </ul>
            <div className="hidden md:block">
              <Button onClick={() => navigate("/")}>Start Booking</Button>
            </div>
            <div className="md:hidden">
              <FiMenu size={28} onClick={toggleMenu} color="white" />
            </div>
          </div>
        </div>
        {menuOpen && (
          <div className="md:hidden flex flex-col bg-white w-full p-4 absolute top-20 shadow-lg z-10">
            <Link to="/" className="py-2 border-b" onClick={toggleMenu}>
              Home
            </Link>
            <Link to="/about" className="py-2 border-b" onClick={toggleMenu}>
              About
            </Link>
            <Link to="/contact" className="py-2" onClick={toggleMenu}>
              Contact
            </Link>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
