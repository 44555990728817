import React, { useEffect } from "react";
import HomeWrapper from "../components/HomeWrapper";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrips } from "../reduxState/features/bookingSlice";
import LoadingSpinner from "../components/LoadingSpinner";

const Home = () => {
  const dispatch = useDispatch();
  const { trips, loading } = useSelector((state) => state.booking);

  useEffect(() => {
    dispatch(fetchTrips());
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <HomeWrapper trips={trips} />
    </div>
  );
};

export default Home;
