import React from "react";
import Layout from "../components/Layout/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="min-h-screen bg-white py-10 px-6">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
          {/* <p className="text-gray-700 mb-4">
          <strong>Effective Date:</strong> [Insert Date]
        </p> */}

          <p className="text-gray-700 mb-4">
            <strong>Vismaya Greenlines Private Limited</strong> (“we”, “us”, or
            “our”) operates the website [insert website URL] (the “Site”) and
            provides transportation services in Kerala and surrounding states.
            This Privacy Policy describes how we collect, use, and protect your
            personal information when you use our Site and services.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            1. Information We Collect
          </h2>
          <p className="text-gray-700 mb-4">
            We may collect personal information from you when you:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-700">
            <li>Visit our Site</li>
            <li>Book transportation services</li>
            <li>Subscribe to our newsletters or updates</li>
            <li>Contact us via email, phone, or WhatsApp</li>
          </ul>
          <p className="text-gray-700 mb-4">
            The types of personal information we may collect include:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-700">
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Payment information</li>
            <li>Travel preferences and other relevant details</li>
          </ul>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            2. How We Use Your Information
          </h2>
          <p className="text-gray-700 mb-4">
            We may use the information we collect for various purposes,
            including:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-700">
            <li>To provide and maintain our services</li>
            <li>To process your bookings and payments</li>
            <li>To communicate with you about your reservations</li>
            <li>To respond to your inquiries and customer support requests</li>
            <li>
              To send you promotional materials and updates (if you have opted
              in)
            </li>
            <li>To improve our website and services</li>
          </ul>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            3. Sharing Your Information
          </h2>
          <p className="text-gray-700 mb-4">
            We do not sell, rent, or trade your personal information to third
            parties. We may share your information with trusted third-party
            service providers who assist us in operating our Site, conducting
            our business, or servicing you, as long as those parties agree to
            keep this information confidential. We may also disclose your
            information when required by law or to protect our rights.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">4. Data Security</h2>
          <p className="text-gray-700 mb-4">
            We take the security of your personal information seriously and
            implement reasonable measures to protect it from unauthorized
            access, use, or disclosure. However, please be aware that no method
            of transmission over the Internet or method of electronic storage is
            100% secure.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">5. Your Rights</h2>
          <p className="text-gray-700 mb-4">You have the right to:</p>
          <ul className="list-disc list-inside mb-4 text-gray-700">
            <li>Access the personal information we hold about you</li>
            <li>Request correction of your personal information</li>
            <li>Request deletion of your personal information</li>
            <li>Opt-out of marketing communications</li>
          </ul>
          <p className="text-gray-700 mb-4">
            To exercise any of these rights, please contact us using the contact
            details below.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            6. Changes to This Privacy Policy
          </h2>
          <p className="text-gray-700 mb-4">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our Site
            with an updated effective date. We encourage you to review this
            Privacy Policy periodically for any changes.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">7. Contact Us</h2>
          <p className="text-gray-700 mb-4">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Customer Care:</strong>{" "}
            <a
              href="mailto:vismayaexpresslines@gmail.com"
              className="text-blue-500 hover:underline"
            >
              vismayaexpresslines@gmail.com
            </a>
          </p>
          <p className="text-gray-700 mb-2">
            <strong>WhatsApp:</strong> 9846090098
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
