import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <div className="relative overflow-x-hidden">
      <Navbar />
      <main className="mb-20">{children}</main>
      <Footer />

      {/* WhatsApp Button */}
      <a
        href="https://wa.me/+919846090098" // Replace with your WhatsApp number
        className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          className="w-8 h-8" // Increased size here
        >
          <path d="M20.52 3.48A11.924 11.924 0 0012 0C5.373 0 0 5.373 0 12c0 2.116.548 4.176 1.584 5.988L0 24l6.13-1.577A11.958 11.958 0 0012 24c6.627 0 12-5.373 12-12 0-3.192-1.248-6.202-3.48-8.52zM12 22a9.963 9.963 0 01-4.59-1.092l-.333-.178-3.634.934.973-3.573-.192-.333A9.973 9.973 0 012 12C2 6.477 6.477 2 12 2c2.65 0 5.174 1.032 7.071 2.929A9.964 9.964 0 0122 12c0 5.523-4.477 10-10 10zm5.16-7.515l-2.593-.798a1.16 1.16 0 00-1.08.195l-.96.72a8.382 8.382 0 01-3.096-3.096l.72-.96a1.16 1.16 0 00.195-1.08l-.798-2.593a1.166 1.166 0 00-1.04-.8h-1.8c-.638 0-1.16.522-1.16 1.16 0 6.255 5.085 11.34 11.34 11.34.638 0 1.16-.522 1.16-1.16v-1.8c0-.51-.33-.962-.8-1.04z" />
        </svg>
      </a>
    </div>
  );
};

export default Layout;
