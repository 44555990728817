import React from "react";

const Button = ({ children, onClick, className, color = "green" }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className={`${
          color === "green" ? "bg-[#a2e53f] text-black" : "bg-[#fff] text-black"
        } px-6 sm:py-3 py-2 rounded-lg transition ${className}`}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
