import React, { useState } from "react";
import Button from "./Button";
import image1 from "../assets/image1.jpg";
import image2 from "../assets/image2.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import Layout from "./Layout/Layout";
import TripCard from "./TripCard";
import TripSearchIput from "./TripSearchInput";
import VisitingTemples from "./VisitingTemples";

const HomeWrapper = ({ trips }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Layout>
      <div className="relative">
        <div>
          {/* Background Section */}
          <div
            className="relative h-[70vh] bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${image1})`,
            }}
          >
            {/* Dark Overlay */}
            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70" />

            {/* Content */}
            <div className="absolute -top-10 left-0 w-full h-full flex flex-col justify-center items-start px-4 sm:px-12">
              <h2 className="text-4xl font-bold mb-6 text-[#fff]">
                SWAMI <span className="text-[#a2e53f]">SARANAM</span>
              </h2>
              <ul className="text-white text-lg list-disc list-inside space-y-2">
                <li>AC pushback semi sleeper buses</li>
                <li>Experienced guide</li>
                <li>Free 5 times food</li>
                <li>Guruvayoor Stay & Sannidhanam Stay</li>
              </ul>

              {/* Button to show modal */}
              <button
                onClick={() => setIsModalOpen(true)}
                className="mt-6 bg-[#a2e53f] text-black px-4 py-2 rounded-md hover:bg-[#8bd02b] transition duration-300"
              >
                Show Visiting Temples
              </button>
            </div>
          </div>

          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 p-6 relative">
                <h2 className="text-2xl font-bold mb-4 text-center">
                  Visiting Temples
                </h2>

                {/* Temple List */}
                <VisitingTemples />

                {/* Close Button */}
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="absolute top-2 right-4 text-gray-500 hover:text-black text-3xl"
                >
                  &times;
                </button>
              </div>
            </div>
          )}
        </div>

        <TripSearchIput trips={trips} />

        {/* <VisitingTemples /> */}

        <section className="max-w-screen-xl mx-auto px-2 pt-10 ">
          <div className="">
            <h2 className="text-3xl font-bold">Who We Are</h2>
            <p className="text-gray-700 mt-4">
              In the travel and tourism sector, Vismaya Greenlines Private
              Limited has established itself by offering transport services
              within Kerala and expanding to neighboring states like Karnataka
              and Tamil Nadu. The company focuses on enhancing efficiency in
              passenger transportation, with plans to diversify into interstate
              bus services. By leveraging its expertise in logistics and
              technology, Vismaya aims to improve travel experiences, offering
              safer and more cost-effective services through monitoring systems
              and AI-based innovations. Our expansion into tourism aligns with
              India's growing market, and Our strategy includes integrating
              advanced technologies to streamline travel operations, such as
              reducing delays and improving service quality
            </p>
          </div>
        </section>

        <section className="max-w-screen-xl mx-auto px-2 pt-28">
          <div className="">
            <h2 className="text-3xl font-bold">Disclaimer</h2>
            <ul className="text-gray-700 mt-4 list-disc list-inside">
              <li>
                Female/Others gender pilgrims with age between 10 years and 50
                years will not be allowed for Darshan.
              </li>
              <li>
                Vismaya Greenlines Private Limited requests all pilgrims to
                follow 'Vritham' and to avoid using Alcohol, Cigarette & other
                substances during the pilgrimage with us
              </li>
              <li>
                As per guidelines from the Travancore Devaswom Board, all
                pilgrims are requested to make a Virtual Queue reservation
                before Darshan.
              </li>
              <li>
                Virtual Queue reservation can be done in advance through the
                website:
                <a
                  href="https://www.sabarimalaonline.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  www.sabarimalaonline.org
                </a>
              </li>
              <li>
                Vismaya Greenlines Pvt Ltd is not responsible for any loss or
                any other illegal activities.
              </li>
              <li>
                If sabarimala stay not available we will provide it in
                Pampa/nilakkal/Erumely.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default HomeWrapper;
