import React from "react";
import Layout from "../components/Layout/Layout";

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className="bg-white text-gray-800 min-h-screen py-10 px-6 md:px-16">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-6">Terms and Conditions</h1>
          {/* <p className="mb-4">**Last Updated: [Date]**</p> */}
          <p className="mb-8">
            Welcome to Vismaya Greenlines. By using our services, you agree to
            comply with and be bound by the following terms and conditions.
            Please read them carefully.
          </p>

          <h2 className="text-2xl font-semibold mb-4">1. General Terms</h2>
          <p className="mb-4">
            1.1. These terms and conditions apply to all bookings, services, and
            usage of the Vismaya Greenlines website.
          </p>
          <p className="mb-8">
            1.2. By accessing or using our services, you agree to comply with
            all applicable laws and regulations. If you do not agree to these
            terms, please do not use our services.
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            2. Booking and Payment
          </h2>
          <p className="mb-4">
            2.1. All bookings are subject to availability and confirmation by
            Vismaya Greenlines.
          </p>
          <p className="mb-4">
            2.2. Payments for bookings must be made online through our secure
            payment gateway. We accept all major debit and credit cards. Payment
            must be made in full at the time of booking.
          </p>
          <p className="mb-8">
            2.3. Vismaya Greenlines reserves the right to cancel or refuse a
            booking if payment is not received or if the payment is
            unauthorized.
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            3. Cancellation and Refund Policy
          </h2>
          <p className="mb-4">
            3.1. Cancellations can be made through our customer service
            helpline. However, cancellations made less than 7 days before
            departure may not be eligible for a refund.
          </p>
          <p className="mb-4">
            3.2. If a trip is canceled by Vismaya Greenlines due to unforeseen
            circumstances (e.g., weather conditions, road closures), we will
            offer a full refund or an alternative booking.
          </p>
          <p className="mb-8">
            3.3. For cancellations made by the customer, a cancellation fee may
            apply depending on the time of cancellation. Refunds, if applicable,
            will be processed within 7 working days.
          </p>

          <h2 className="text-2xl font-semibold mb-4">4. Passenger Conduct</h2>
          <p className="mb-4">
            4.1. Passengers must follow the instructions of the driver and staff
            at all times for their safety.
          </p>
          <p className="mb-4">
            4.2. Smoking, alcohol consumption, and carrying hazardous items are
            strictly prohibited on our buses. Any passenger found violating
            these rules may be removed from the bus without a refund.
          </p>
          <p className="mb-8">
            4.3. Vismaya Greenlines reserves the right to refuse service or
            remove any passenger who causes a disturbance or poses a threat to
            the safety of others.
          </p>

          <h2 className="text-2xl font-semibold mb-4">5. Luggage Policy</h2>
          <p className="mb-4">
            5.1. Each passenger is allowed to carry one piece of checked luggage
            and one piece of hand luggage. Excessive luggage may be subject to
            additional charges.
          </p>
          <p className="mb-8">
            5.2. Vismaya Greenlines is not responsible for any lost or damaged
            luggage. Passengers are advised to keep their belongings secure at
            all times.
          </p>

          <h2 className="text-2xl font-semibold mb-4">6. Liability</h2>
          <p className="mb-4">
            6.1. Vismaya Greenlines will not be liable for any delays,
            cancellations, or disruptions caused by unforeseen circumstances
            such as weather, traffic, or mechanical failures.
          </p>
          <p className="mb-8">
            6.2. Vismaya Greenlines is not responsible for any indirect,
            incidental, or consequential damages resulting from the use of our
            services.
          </p>

          <h2 className="text-2xl font-semibold mb-4">7. Privacy Policy</h2>
          <p className="mb-4">
            7.1. By using our services, you agree to our collection and use of
            your personal information in accordance with our Privacy Policy.
          </p>
          <p className="mb-8">
            7.2. We are committed to protecting your privacy and ensuring that
            your personal information is secure. For more details, please refer
            to our Privacy Policy page.
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            8. Changes to the Terms
          </h2>
          <p className="mb-4">
            8.1. Vismaya Greenlines reserves the right to modify or update these
            terms and conditions at any time. Changes will be posted on our
            website, and it is your responsibility to review the terms
            periodically.
          </p>
          <p className="mb-8">
            8.2. Continued use of our services after any changes constitutes
            your acceptance of the revised terms.
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            9. Contact Information
          </h2>
          <p className="mb-4">
            For any questions or concerns regarding these terms, please contact
            us at:
          </p>
          <p className="mb-4">**Email**: vismayaexpresslines@gmail.com</p>
          <p className="mb-8">**WhatsApp**: 9846090098</p>

          <h2 className="text-2xl font-semibold mb-4">10. Governing Law</h2>
          <p className="mb-4">
            These terms and conditions are governed by the laws of India. Any
            disputes arising from the use of our services will be subject to the
            jurisdiction of the courts in Kerala.
          </p>

          <p className="mt-10 text-gray-600">
            © Vismaya Greenlines. All rights reserved.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
