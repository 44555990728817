import fetch from ".";

const bustopService = {};

const Route = "/busstop";

bustopService.getBustops = async function (tripId) {
  try {
    const { data } = await fetch({
      url: `${Route}`,
      method: "get",
    });

    return data;
  } catch (err) {
    console.log(err);
  }
};

export default bustopService;
