import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import tripService from "../../services/tripService";
import busStopService from "../../services/bustopService";

const initialState = {
  loading: false,
  trips: [],
  selectedTrip: {},
  busStops: [],
};

export const fetchTrips = createAsyncThunk("trip/fetch", async () => {
  const response = await tripService.getTrip();
  return response.data;
});

export const fetchTripById = createAsyncThunk("trip/fetchById", async (id) => {
  const response = await tripService.getTripById(id);
  return response;
});

export const fetchBustops = createAsyncThunk("bustop/fetchByTrip", async () => {
  const response = await busStopService.getBustops();
  return response;
});

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrips.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchTrips.fulfilled, (state, action) => {
        state.loading = false;
        state.trips = action.payload;
      })
      .addCase(fetchTrips.rejected, (state, action) => {
        state.loading = false;
      })

      // FetchTripByID
      .addCase(fetchTripById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchTripById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedTrip = action.payload;
      })
      .addCase(fetchTripById.rejected, (state, action) => {
        state.loading = false;
      })

      //Fetch Bus Stop By Trip ID
      .addCase(fetchBustops.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchBustops.fulfilled, (state, action) => {
        state.loading = false;
        state.busStops = action.payload[0].busStops;
      })
      .addCase(fetchBustops.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = bookingSlice.actions;

export default bookingSlice.reducer;
