import React from "react";

const VisitingTemples = () => {
  const temples = [
    "Kadampuzha Bhagavathy Temple",
    "Guruvayoor Sreekrishna Temple",
    // "Mammiyoor Shiva Temple",
    "Kodungaloor Bhagavathy Temple",
    "Chottanikkara",
    "Vaikom",
    "Kaduthuruthy",
    "Malliyoor Ganapathy Temple",
    "Ettumanoor",
    "Erumely Temple/Vavar Mosque",
  ];

  return (
    <ul className="text-lg text-gray-700 list-disc list-inside space-y-2">
      {temples.map((temple, index) => (
        <li key={index}>{temple}</li>
      ))}
    </ul>
  );
};

export default VisitingTemples;
