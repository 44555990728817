import fetch from ".";

const tripService = {};

const Route = "/trip";

tripService.getTrip = async function () {
  try {
    const data = await fetch({
      url: `${Route}?onGoingBooking=true`,
      method: "get",
    });

    return data;
  } catch (err) {
    console.log(err);
  }
};

tripService.getTripById = async function (id) {
  try {
    const { data } = await fetch({
      url: `${Route}/${id}`,
      method: "get",
    });

    return data;
  } catch (err) {
    console.log(err);
  }
};

export default tripService;
