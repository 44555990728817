import React from "react";
import Layout from "../components/Layout/Layout";

const CancellationRefundPolicy = () => {
  return (
    <Layout>
      <div className="bg-white text-gray-800 min-h-screen py-10 px-6 md:px-16">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-6">
            Cancellation & Refund Policy
          </h1>
          <p className="mb-8">
            At Vismaya Greenlines, we strive to provide the best travel
            experience for our customers. However, we understand that sometimes
            plans change, and you may need to cancel or modify your booking.
            Please read our cancellation and refund policy carefully to
            understand how we manage cancellations and refunds.
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            1. Cancellation Policy
          </h2>
          <p className="mb-4">
            1.1. Cancellations can be made through by contacting our customer
            service team at least 7 days before journey after that we will not
            refund the money.
          </p>
          {/* <p className="mb-4">
            1.2. Cancellations and will not accept bmade less than 7 days before the departure
            may not be eligible for a refund.
          </p> */}
          <p className="mb-8">
            1.2. If you encounter any issues while canceling your trip, please
            reach out to our support team at{" "}
            <a
              href="mailto:vismayaexpresslines@gmail.com"
              className="text-blue-500"
            >
              vismayaexpresslines@gmail.com
            </a>{" "}
            or WhatsApp us at 9846090098.
          </p>

          <h2 className="text-2xl font-semibold mb-4">2. Refund Policy</h2>
          <p className="mb-4">
            2.1. If you cancel your booking at least 7 days before the departure
            time, you may be eligible for a full refund.
          </p>
          <p className="mb-4">
            2.2. Refunds will be processed within 7 working days of the
            cancellation date. The refund will be made to the original payment
            method used during the booking.
          </p>
          <p className="mb-8">
            2.3. Please note that refunds may take additional time to reflect in
            your account, depending on your bank or payment provider.
          </p>

          <h2 className="text-2xl font-semibold mb-4">3. No-Show Policy</h2>
          <p className="mb-4">
            3.1. If you fail to show up at the designated departure location
            without prior notice, no refund will be issued.
          </p>
          <p className="mb-8">
            3.2. In case of any unforeseen circumstances, please contact us as
            soon as possible to discuss available options.
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            4. Changes or Cancellations by Us
          </h2>
          <p className="mb-4">
            4.1. In the event of a cancellation or change initiated by Vismaya
            Greenlines (e.g., due to weather conditions or operational issues),
            we will offer you the option to reschedule your trip or receive a
            full refund.
          </p>
          <p className="mb-8">
            4.2. We will notify you of any such changes as soon as possible and
            work to minimize any inconvenience caused.
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            5. Contact Information
          </h2>
          <p className="mb-4">
            For any questions or concerns regarding cancellations or refunds,
            please contact our customer care team:
          </p>
          <p className="mb-4">
            **Email**:{" "}
            <a
              href="mailto:vismayaexpresslines@gmail.com"
              className="text-blue-500"
            >
              vismayaexpresslines@gmail.com
            </a>
          </p>
          <p className="mb-8">**WhatsApp**: 9846090098</p>

          <h2 className="text-2xl font-semibold mb-4">
            6. Amendments to the Policy
          </h2>
          <p className="mb-8">
            Vismaya Greenlines reserves the right to modify or amend this
            cancellation and refund policy at any time. Changes will be
            effective immediately upon being posted on our website. We encourage
            you to review this policy periodically to stay informed.
          </p>

          <p className="mt-10 text-gray-600">
            © Vismaya Greenlines. All rights reserved.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default CancellationRefundPolicy;
